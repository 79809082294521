import moment from 'moment';
import Vue from 'vue';

export default class GlobalFilters {
  static install() {
    Vue.filter('date', function (value, format, isUtc) {
      const momentObj = isUtc ? moment.utc(value) : moment(value);
      return momentObj.format(format);
    });
  }
}
