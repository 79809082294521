<template>
  <v-card class="zuga-card" color="transparent" flat :style="style">
    <div class="zuga-card__title pa-0">{{ title }}</div>
    <div class="zuga-card__subtitle mb-5">{{ desc }}</div>
    <v-card-text class="zuga-card-text rounded-lg">
      <v-data-table
        ref="dataTable"
        class="zuga-grid zuga-base"
        flat
        disable-hover
        :footer-props="perPageOptions"
        :show-select="multipleDelete || multipleSelection"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="count"
        :loading="loading"
        v-model="selected"
      >
        <template v-slot:top>
          <form-dialog
            v-if="form"
            :form-loading="loading"
            :form-dialog.sync="formDialogIsOpen"
            :form-component="form.component"
            :title="formTitle"
            @save="saveItem"
          />

          <z-alert-dialog
            confirm-box
            :alert-dialog.sync="alertDialogIsOpen"
            :confirmFn="deleteItem"
            type="warning"
            title="Are you sure you want to delete item ?"
          />

          <z-alert-dialog
            :alert-dialog.sync="processDialog.isOpen"
            :type="processDialog.type"
            :title="processDialog.title"
          />

          <v-container fluid class="pt-2 pb-5" v-if="customFilters">
            <slot name="customFilters"></slot>
            <div class="row">
              <div class="col-6">
                <v-btn
                  class="zuga-btn mr-2"
                  depressed
                  dark
                  color="zuga-blue"
                  @click="getGridData()"
                >
                  Search
                </v-btn>
                <v-btn
                  class="zuga-btn mr-2"
                  depressed
                  dark
                  color="zuga-red"
                  @click="$emit('clear-filter', () => getGridData())"
                >
                  Clear
                </v-btn>
              </div>
            </div>
          </v-container>
          <div class="d-flex align-center pt-2 pb-5">
            <div class="d-flex">
              <div class="search ma-1 pa-1" v-if="showSearch">
                <z-text-field
                  placeholder="Search"
                  icon="mdi-magnify"
                  font-weight="500"
                  v-model="keyword"
                  :loading="loading"
                  @input="debouncedSearch"
                />
              </div>
            </div>
            <v-spacer></v-spacer>

            <div class="zuga-grid__top-buttons">
              <div v-if="customGridProcessButtons">
                <v-btn
                  v-for="(processButton, index) in customGridProcessButtons"
                  class="zuga-btn mr-2"
                  depressed
                  :dark="false"
                  color="zuga-blue"
                  :key="`processButton-${index}`"
                  :class="processButton.class"
                  :loading="clickedLoadingIndex === index"
                  :disabled="processButtonCheckDisable(processButton)"
                  @click="
                    handleGridProcessButtonClick(index, processButton.action)
                  "
                >
                  {{ processButton.text }}
                </v-btn>
              </div>

              <v-btn
                class="zuga-btn"
                depressed
                dark
                color="zuga-red"
                @click="deleteConfirm"
                v-if="multipleDelete"
              >
                Delete Choosen
              </v-btn>
              <v-btn
                class="zuga-btn"
                depressed
                dark
                color="zuga-blue"
                @click="addItem"
                v-if="defaultActions.insert"
              >
                Create
              </v-btn>
            </div>
          </div>
        </template>

        <template
          v-for="(header, index) in headers"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <div :key="`header-${index}`">
            <template
              v-if="
                header.isDate &&
                item[header.value] !== null &&
                item[header.value] !== undefined
              "
            >
              {{
                item[header.value] | date(header.isDate.format, header.isUtc)
              }}
            </template>
            <template v-else-if="header.setText">
              {{ header.setText(item) }}
            </template>
            <template v-else-if="header.button">
              <button-field
                :item="item"
                :config="header.button.config"
                :reverse-config="header.button.reverseConfig"
                :loading="header.button.isLoading(item)"
              ></button-field>
            </template>
            <template v-else>
              {{ item[header.value] }}
            </template>
          </div>
        </template>

        <template v-slot:[`header.data-table-select`]="{ on, props }">
          <v-icon
            light
            :color="props.value ? 'zuga-blue' : 'zuga-grid-checkbox'"
            v-bind="props"
            v-on="on"
            @click="on.input(!props.value)"
          >
            {{ props.value ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}
          </v-icon>
        </template>

        <template v-slot:[`item.data-table-select`]="{ select, isSelected }">
          <v-icon
            light
            :color="isSelected ? 'zuga-blue' : 'zuga-grid-checkbox'"
            @click="select(!isSelected)"
          >
            {{ isSelected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}
          </v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }" v-if="showAction">
          <action-buttons
            :buttons="gridButtons"
            :item="item"
            class="zuga-grid-buttons"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import { CommonConst, GridConst } from '@/common/store.constants';
  import ActionButtons from './ActionButtons.vue';
  import ButtonField from './ButtonField.vue';
  import FormDialog from './FormDialog.vue';
  export default {
    components: { FormDialog, ButtonField, ActionButtons },
    name: 'ZGrid',
    props: {
      title: {
        type: String,
        required: true,
      },
      desc: {
        type: String,
        required: true,
      },
      config: {
        type: Object,
        required: true,
        default: () => {},
      },
      customFilters: {
        type: Object,
        required: false,
        default: () => null,
      },
    },
    data() {
      return {
        modal: false,
        keyword: '',
        formTitle: '',
        options: {
          itemsPerPage: 25,
        },
        formDialogIsOpen: false,
        alertDialogIsOpen: false,
        selected: [],
        debouncedSearch: null,
        deletingItem: null,
        processDialog: {
          isOpen: false,
          type: '',
          title: '',
        },
        clickedLoadingIndex: null,
      };
    },
    computed: {
      style() {
        return {
          '--v-action-menu-shadow': this.$store.getters[CommonConst.THEME]
            ? '0px 0px 30px rgba(0, 0, 0, 0.3)'
            : '0px 0px 50px 0px rgba(82, 63, 105, 0.15)',
        };
      },
      headers() {
        const actionMenu = {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          width: 0,
          sortable: false,
        };

        return [
          ...this.$store.getters[GridConst.HEADERS],
          ...(this.showAction ? [actionMenu] : []),
        ];
      },
      items() {
        return this.$store.getters[GridConst.ITEMS];
      },
      count() {
        return this.$store.getters[GridConst.COUNT];
      },
      defaultActions() {
        return this.$store.getters[GridConst.DEFAULT_ACTIONS];
      },
      customGridButtons() {
        return this.$store.getters[GridConst.CUSTOM_GRID_BUTTONS];
      },
      customGridProcessButtons() {
        return this.$store.getters[GridConst.CUSTOM_GRID_PROCESS_BUTTONS];
      },
      loading() {
        return this.$store.getters[CommonConst.IS_LOADING];
      },
      endpoints() {
        return this.$store.getters[GridConst.ENDPOINTS];
      },
      formData() {
        return this.$store.getters[GridConst.FORM_DATA];
      },
      showAction() {
        return (
          Object.values(this.defaultActions).some((x) => x) ||
          Object.values(this.customGridButtons).some((x) => x)
        );
      },
      form() {
        return this.config.form;
      },
      multipleDelete() {
        return this.config.multipleDelete ?? false;
      },
      multipleSelection() {
        return this.config.multipleSelection ?? false;
      },
      showSearch() {
        return (this.config.showSearch && !this.customFilters) ?? true;
      },
      showDate() {
        return this.config.showDate ?? true;
      },
      perPageOptions() {
        return {
          'items-per-page-options': [5, 25, 50, 100],
        };
      },
      gridButtons() {
        return [
          ...(this.defaultActions.update
            ? [{ text: 'Edit', action: this.editItem }]
            : []),
          ...(this.defaultActions.delete
            ? [{ text: 'Delete', action: this.deleteConfirm }]
            : []),
          ...this.customGridButtons,
        ];
      },
      customFilterPayloads() {
        return this.customFilters?.payload;
      },
      allSelected() {
        return this.selected.length === this.items.length;
      },
    },
    mounted() {
      this.setConfig();
      this.debouncedSearch = this.$debounce(this.search, 500, {
        trailing: true,
      });
    },
    methods: {
      setConfig() {
        this.$store.commit(GridConst.SET_CONFIG, this.config);
      },
      editItem(item) {
        this.formTitle = `${this.title} - Edit`;
        this.$gridController
          .getPreset(this.endpoints.getPreset, { id: item.id })
          .then(() => {
            this.formDialogIsOpen = true;
          });
      },
      addItem() {
        this.formTitle = `${this.title} - Create`;
        this.$gridController.getPreset(this.endpoints.getPreset).then(() => {
          this.formDialogIsOpen = true;
        });
      },
      saveItem({ successFn, errorFn = null }) {
        const { $gridController, endpoints, getItem } = this;

        $gridController
          .save(endpoints.insert, this.formData)
          .then(async (response) => {
            await getItem(response);
            this.formDialogIsOpen = false;

            this.processDialog = {
              isOpen: true,
              type: 'success',
              title: 'Form has been successfully submitted!',
            };

            successFn();
          })
          .catch(() => {
            errorFn();
          });
      },
      getItem(id) {
        return this.$gridController.get(this.endpoints.get, {
          id: id,
        });
      },
      deleteConfirm(item) {
        this.deletingItem = item;
        this.alertDialogIsOpen = true;
      },
      deleteItem() {
        const { deletingItem, $gridController, endpoints } = this;

        if (!deletingItem) return;

        $gridController
          .delete(endpoints.delete, deletingItem)
          .then(() => {
            this.deletingItem = null;
            this.alertDialogIsOpen = false;
          })
          .catch(() => {
            this.processDialog = {
              isOpen: true,
              type: 'error',
              title: 'An error occurred while submitting the form!',
            };
          });
      },

      search() {
        if (this.keyword.length < 3 && this.keyword.length !== 0) return;
        this.getGridData(this.keyword, this.date);
      },
      getGridData(keyword = '') {
        const {
          page,
          itemsPerPage: pageRecordCount,
          sortBy,
          sortDesc,
        } = this.options;

        const payload = {
          page,
          searchText: keyword,
          pageRecordCount,
          ...(sortBy.length > 0 && {
            sortFieldName: sortBy[0]?.toLowerCase(),
            sortDesc: sortDesc[0],
          }),
          ...(this.customFilterPayloads && {
            customFilters: {
              ...this.customFilterPayloads,
            },
          }),
        };

        this.$gridController
          .getGridData(this.endpoints.getGridData, payload)
          .finally(() => {
            this.selected = [];
          });
      },
      // eslint-disable-next-line no-unused-vars
      async handleGridProcessButtonClick(index, action) {
        this.clickedLoadingIndex = index;
        await action(this.selected, this.allSelected).finally(() => {
          this.clickedLoadingIndex = null;
        });
      },
      processButtonCheckDisable(button) {
        let isDisabled = false;

        if (button.disabled !== undefined) {
          if (typeof button.disabled === 'function') {
            isDisabled = button.disabled(this.selected);
          } else if (typeof button.disabled === 'object') {
            isDisabled = button.disabled.valueOf();
          } else {
            isDisabled = !!button.disabled;
          }
        }

        return isDisabled;
      },
    },
    watch: {
      options: {
        handler() {
          this.getGridData(this.keyword, this.date);
        },
        deep: true,
      },
      formDialogIsOpen: {
        handler(value) {
          if (!value) this.$store.commit(GridConst.RESET_ITEM);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .zuga-card {
    &__title {
      font-size: 1rem !important;
      font-weight: 600;
      color: var(--v-zuga-card-title-base);
    }

    &__subtitle {
      font-size: 0.77rem !important;
      font-weight: 500;
      color: var(--v-zuga-card-subtitle-base) !important;
    }

    .zuga-card-text {
      box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02) !important;
      background-color: var(--v-zuga-base-base);
      padding: 20px !important;
    }

    .zuga-grid {
      &__top-buttons {
        column-gap: 10px;
        display: flex;
      }
    }
  }

  :deep() {
    tbody tr {
      &:nth-of-type(even) {
        background-color: transparent;
      }
      &:nth-of-type(odd) {
        background-color: transparent;
      }

      &:hover {
        background: transparent !important;
      }

      &:not(:last-child) {
        td {
          border-bottom: 1px solid var(--v-zuga-grid-border-base) !important;
        }
      }

      td {
        color: var(--v-zuga-grid-text-base) !important;
      }
      &.v-data-table__selected {
        background-color: transparent !important;
      }
    }

    .v-data-table-header {
      background-color: transparent;

      th {
        border-bottom: 1px solid var(--v-zuga-grid-border-base) !important;
        color: var(--v-zuga-grid-text-base) !important;
      }
    }

    .v-data-footer {
      border-top: 1px solid var(--v-zuga-grid-border-base) !important;
      background-color: transparent;
    }
  }

  .zuga-input {
    font-size: 0.88rem !important;
    font-weight: var(--v-weight);
    background-color: var(--v-zuga-input-bg-base) !important;
  }
</style>
