import { GridConst } from '@/common/store.constants';

const hideFilter = ({ show }) =>
  show === undefined
    ? true
    : typeof show === 'function'
    ? show()
    : Boolean(show);

const getDefaultState = () => ({
  config: {
    name: null,
    endpoints: {},
    headers: [],
    form: {
      pk: 'id',
      defaultItem: null,
      dataField: null,
    },
  },
  options: {},
  items: [],
  count: 0,
  preset: null,
  filterPreset: null,
  formData: null,
});

const state = getDefaultState();

const mutations = {
  setData(state, { rows, totalRecordCount }) {
    Object.assign(state, { items: rows, count: totalRecordCount });
  },
  setConfig(state, config) {
    Object.assign(state, { config });
  },
  setOptions(state, options) {
    Object.assign(state, { options });
  },
  editItem(state, value) {
    Object.assign(state, { formData: value });
  },
  setPreset(state, preset) {
    Object.assign(state, { preset });
  },
  setFilterPreset(state, filterPreset) {
    Object.assign(state, { filterPreset });
  },
  reset(state) {
    Object.assign(state, { items: [], count: 0 });
  },
  resetItem: (state) => Object.assign(state, { preset: null, formData: null }),
  deleteItem(state, item) {
    const pk = this.getters[GridConst.PK_FIELD];
    const index = state.items.findIndex((x) => x[pk] === item[pk]);
    state.items.splice(index, 1);
  },
  upsertItem(state, item) {
    const pk = this.getters[GridConst.PK_FIELD];

    const index = state.items.findIndex((x) => x[pk] === item[pk]);
    if (index === -1) state.items.unshift(item);
    else state.items.splice(index, 1, item);

    if (state.items.length > this.getters[GridConst.OPTIONS].itemsPerPage)
      state.items.pop();
  },
};

const actions = {};

const getters = {
  options: (state) => state.options,
  items: (state) => state.items,
  count: (state) => state.count,
  endpoints: (state) => state.config.endpoints,
  headers: (state) => state.config.headers.filter(hideFilter),
  defaultActions: (state) => {
    const { endpoints } = state.config;
    const hasEndpoint = (endpoint) => typeof endpoint !== 'undefined';
    return {
      insert: hasEndpoint(endpoints.insert),
      update: hasEndpoint(endpoints.update),
      delete: hasEndpoint(endpoints.delete),
    };
  },
  customGridButtons: (state) => state.config.customGridButtons ?? [],
  customGridProcessButtons: (state) =>
    state.config.customGridProcessButtons ?? [],
  name: (state) => state.config.name,
  pkField: (state) => state.config.form.pk,
  preset: (state) => state.preset,
  filterPreset: (state) => state.filterPreset,
  defaultFormData: (state) => state.config.form.defaultItem,
  formData: (state) => state.formData,
  dataField: (state) => state.config.form.dataField,
  isEditMode: (state, getters) => {
    const formData = getters.formData;
    const pkField = getters.pkField;
    return formData !== null && typeof formData[pkField] === 'number';
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
