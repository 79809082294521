import HttpService from '@/common/http.service';
import Vue from 'vue';
import VueAxios from 'vue-axios';

class GoogleService extends HttpService {
  constructor() {
    super('http://google.com/');
    Vue.use(VueAxios, this);
  }
}

export default GoogleService;
