<template>
  <div>
    <v-btn
      class="text-capitalize transition-class"
      small
      dense
      depressed
      :color="resolvedColor"
      :class="[`${resolvedTextColor}--text`]"
      :loading="loading"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      @click="action"
    >
      <v-icon left small v-if="resolvedIcon">
        {{ resolvedIcon }}
      </v-icon>
      {{ resolvedText }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      item: Object,
      config: Object,
      loading: {
        type: Boolean,
        default: () => false,
      },
      reverseConfig: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        hovered: false,
      };
    },
    computed: {
      resolvedColor() {
        const originalColor = this.getResolvedValue(
          this.config.color,
          this.item
        );
        return this.hovered && this.reverseConfig.color
          ? this.getResolvedValue(
              this.reverseConfig.color,
              this.item,
              originalColor,
              true
            )
          : originalColor;
      },
      resolvedTextColor() {
        const originalTextColor = this.getResolvedValue(
          this.config.textColor,
          this.item
        );
        return this.hovered && this.reverseConfig.textColor
          ? this.getResolvedValue(
              this.reverseConfig.textColor,
              this.item,
              originalTextColor,
              true
            )
          : originalTextColor;
      },
      resolvedIcon() {
        const originalIcon = this.getResolvedValue(this.config.icon, this.item);
        return this.hovered && this.reverseConfig.icon
          ? this.getResolvedValue(
              this.reverseConfig.icon,
              this.item,
              originalIcon,
              true
            )
          : originalIcon;
      },
      resolvedText() {
        const originalText = this.getResolvedValue(this.config.text, this.item);
        return this.hovered && this.reverseConfig.text
          ? this.getResolvedValue(
              this.reverseConfig.text,
              this.item,
              originalText,
              true
            )
          : originalText;
      },
    },
    methods: {
      action() {
        if (typeof this.config.action === 'function') {
          this.config.action(this.item);
        }
      },
      getResolvedValue(value, item, defaultValue = null, isReverse = false) {
        if (typeof value === 'function') {
          return isReverse ? value(defaultValue, item) : value(item);
        }
        return value || defaultValue;
      },
    },
  };
</script>
<style scoped>
  .transition-class {
    transition: background-color 0.5s ease, color 0.5s ease,
      box-shadow 0.4s ease;
  }
</style>
