<template>
  <v-btn
    height="40"
    width="40"
    min-width="40"
    class="box-button"
    text
    :ripple="false"
    @click="handleAction"
  >
    <v-icon size="20" class="icon">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'ZIconButton',
    props: {
      icon: { type: String, required: true },
    },
    methods: {
      handleAction(index) {
        this.$emit('action', index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .box-button:hover .v-btn__content .icon {
    color: var(--v-zuga-blue-base) !important;
  }

  .box-button .v-btn__content .icon {
    color: grey;
  }
</style>
