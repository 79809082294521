import BaseController from '@/common/base.controller';
import { AuthConst, CommonConst } from '@/common/store.constants';
import store from '@/store';
import Vue from 'vue';

class AuthController extends BaseController {
  constructor(httpService) {
    super(httpService);
  }

  async login(payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      this.httpService
        .post('user/login', payload)
        .then(async (response) => {
          store.commit(AuthConst.SET_USER, response);
          await Vue.prototype.$roleController.getPermissions({
            isSystemRule: false,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }

  async logout() {
    return new Promise((resolve) => {
      store.commit(AuthConst.CLEAR_USER);
      Vue.prototype.$secureLs.removeAll();
      resolve();
    });
  }

  async verifyToken() {
    return new Promise((resolve, reject) => {
      this.httpService
        .post('user/verifytoken')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default AuthController;
