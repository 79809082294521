import BaseController from '@/common/base.controller';
import { AuthConst } from '@/common/store.constants';
import store from '@/store';

class RoleController extends BaseController {
  constructor(httpService) {
    super(httpService);
  }

  async getPermissions(payload) {
    return new Promise((resolve, reject) => {
      this.httpService
        .post('role/getuirights', payload)
        .then((response) => {
          store.commit(AuthConst.SET_PERMISSION, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default RoleController;
