<template>
  <v-dialog
    content-class="z-dialog"
    overlay-color="zuga-overlay"
    v-model="dialog"
    persistent
    :max-width="maxWidth"
    :style="style"
  >
    <v-card color="zuga-base" elevation="0">
      <v-card-text class="text-center pt-15 pb-0">
        <v-icon size="75">{{ icon }}</v-icon>
        <div class="py-5">{{ title }}</div>
      </v-card-text>
      <v-card-actions class="pb-10">
        <v-spacer></v-spacer>
        <v-btn
          class="zuga-btn"
          depressed
          dark
          color="zuga-red"
          @click="confirm"
          v-if="confirmBox"
        >
          Yes
        </v-btn>
        <v-btn
          class="zuga-btn"
          depressed
          text
          v-if="confirmBox && closable"
          @click="close"
        >
          No, Cancel
        </v-btn>
        <v-btn
          class="zuga-btn"
          depressed
          dark
          color="zuga-blue"
          @click="close"
          v-if="!confirmBox"
        >
          Okay, Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { CommonConst } from '@/common/store.constants';
  export default {
    name: 'ZAlertDialog',
    props: {
      alertDialog: {
        default: false,
      },
      confirmBox: {
        type: Boolean,
      },
      type: {
        type: String,
        default: 'info',
      },
      title: {
        type: String,
        required: true,
      },
      maxWidth: {
        default: '400px',
      },
      closable: {
        default: true,
      },
      confirmFn: {
        required: false,
        type: Function,
      },
    },
    data() {
      return {
        dialog: false,
      };
    },
    computed: {
      style() {
        return {
          '--v-dialog-shadow': this.$store.getters[CommonConst.THEME]
            ? '0 0.5rem 1rem rgba(0, 0, 0, 0.1)'
            : '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
        };
      },
      icon() {
        switch (this.type) {
          case 'warning':
            return 'mdi-alert-circle-outline';
          case 'info':
            return 'mdi-information-outline';
          case 'error':
            return 'mdi-close-circle-outline';
          case 'success':
            return 'mdi-check-circle-outline';
          default:
            return 'mdi-information-outline';
        }
      },
    },
    mounted() {
      this.dialog = this.alertDialog;
    },
    methods: {
      close() {
        this.$emit('update:alertDialog', false);
      },
      confirm() {
        this.confirmFn();
      },
    },
    watch: {
      alertDialog: {
        handler() {
          this.dialog = this.alertDialog;
        },
      },
    },
  };
</script>

<style scoped></style>
