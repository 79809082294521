import BaseController from '@/common/base.controller';
import { ReportConst } from '@/common/store.constants';
import store from '@/store';

class ReportController extends BaseController {
  constructor(httpService) {
    super(httpService);
  }

  async getChartData(path, payload) {
    store.commit(ReportConst.SET_DATA, null);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(path, payload)
        .then((response) => {
          store.commit(ReportConst.SET_DATA, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default ReportController;
