<template>
  <div>
    <v-menu
      tile
      min-width="120"
      content-class="action-menu"
      offset-y
      nudge-bottom="0"
      left
      allow-overflow
      close-on-content-click
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          link
          depressed
          small
          class="grid-actions-btn text-capitalize"
          height="32"
          v-bind="attrs"
          v-on="on"
        >
          Actions
          <v-icon right small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list nav color="transparent">
        <v-list-item-group color="zuga-action-btn-bg">
          <v-list-item
            color="zuga-blue-hover"
            link
            v-for="(btn, index) in buttons"
            :key="`grid-btn-${index}`"
            @click="btn.action(item)"
          >
            <v-list-item-title>{{ btn.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'ActionButtons',
    props: {
      buttons: {
        type: Array,
        default: () => [],
      },
      item: {
        type: Object,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .action-menu {
    padding: 4px;
    background-color: var(--v-zuga-action-btn-bg-base) !important;
    border-radius: 0.475rem !important;
    box-shadow: var(--v-action-menu-shadow) !important;
    font-size: 0.7rem !important;

    .v-list-item {
      margin-bottom: 0px !important;
      &:hover,
      &:focus {
        background-color: var(--v-zuga-blue-hover-base) !important;
        .v-list-item__title {
          color: var(--v-zuga-blue-base) !important ;
        }
      }
      min-height: 36px !important;
      &__title {
        color: var(--v-zuga-grid-text-base) !important;
        font-size: 0.7rem !important;
      }
    }
  }
  .grid-actions-btn {
    font-size: 0.7rem;
    font-weight: 500;
    background-color: var(--v-zuga-action-btn-bg-base) !important;
    color: var(--v-zuga-action-btn-text-base) !important;
    &:hover,
    &:focus {
      background-color: var(--v-zuga-blue-hover-base) !important;
      color: var(--v-zuga-blue-base) !important;
    }
  }
</style>
