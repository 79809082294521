import ZAlertDialog from '@/components/ZAlertDialog/Index.vue';
import ZComboBox from '@/components/ZComboBox/Index.vue';
import ZGrid from '@/components/ZGrid/Index.vue';
import ZIconButton from '@/components/ZIconButton/Index.vue';
import ZPageList from '@/components/ZPageList/Index.vue';
import ZTextField from '@/components/ZTextField/Index.vue';
import ZTimePicker from '@/components/ZTimePicker/Index.vue';
import ZChart from '@/components/ZChart/Index.vue';
import ZTextArea from '@/components/ZTextArea/Index.vue';
import Vue from 'vue';

export default class GlobalComponents {
  static install() {
    Vue.component('z-icon-button', ZIconButton);
    Vue.component('z-text-field', ZTextField);
    Vue.component('z-combo-box', ZComboBox);
    Vue.component('z-grid', ZGrid);
    Vue.component('z-alert-dialog', ZAlertDialog);
    Vue.component('z-page-list', ZPageList);
    Vue.component('z-time-picker', ZTimePicker);
    Vue.component('z-chart', ZChart);
    Vue.component('z-text-area', ZTextArea);
  }
}
