class BaseController {
  constructor(httpService) {
    this.httpService = httpService;
  }

  async get(url, payload) {
    return await this.httpService.post(url, payload);
  }

  async getPreset(url, payload) {
    return await this.httpService.post(url, payload);
  }

  async getGridData(url, payload) {
    return await this.httpService.post(url, payload);
  }

  async insert(url, data) {
    return await this.httpService.post(url, data);
  }

  async update(url, data) {
    return await this.httpService.post(url, data);
  }

  async delete(url, payload) {
    return await this.httpService.post(url, payload);
  }
}

export default BaseController;
