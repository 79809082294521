import GoogleService from '@/services/google.service';
import PaasService from '@/services/paas.service';

const GlobalService = {
  install(Vue) {
    Vue.prototype.$paasService = new PaasService();
    Vue.prototype.$googleService = new GoogleService();
  },
};

export default GlobalService;
