import SecureLS from 'secure-ls';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
const ls = new SecureLS({ isCompression: false });

Vue.prototype.$secureLs = ls;

import auth from './modules/auth.module';
import common from './modules/common.module';
import grid from './modules/grid.module';
import report from './modules/report.module';

Vue.use(Vuex);

const commonState = createPersistedState({
  key: 'common',
  paths: ['common.darkTheme'],
});

const authState = createPersistedState({
  key: 'auth',
  paths: ['auth.token', 'auth.user', 'auth.permissions'],
  storage: {
    getItem: (key) => {
      try {
        return ls.get(key);
      } catch (ex) {
        new Error(ex);
      }
    },
    setItem: (key, value) => {
      const json = JSON.parse(value);
      if (!json?.auth?.user) return;
      ls.set(key, value);
    },
    removeItem: (key) => ls.remove(key),
  },
});

export default new Vuex.Store({
  modules: {
    common,
    auth,
    grid,
    report,
  },
  plugins: [commonState, authState],
});
