<template>
  <div class="z-input" :style="style">
    <div class="z-input__label" v-if="label">
      {{ label }}
      <div class="required" v-if="required">*</div>
    </div>

    <v-dialog
      ref="dialog"
      v-model="dialog"
      persistent
      width="290px"
      :return-value.sync="time"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details="auto"
          dense
          solo
          flat
          color="zuga-blue"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
          :clearable="clearable"
          :value="formatTime(time)"
          :background-color="bgColor"
          :class="mergedClass"
          :name="inputName"
          :placeholder="placeholder"
          :loading="loading"
          :error-messages="errorMessage"
          @input="updateValue"
        ></v-text-field>
      </template>
      <v-time-picker
        color="zuga-blue"
        full-width
        :value="value"
        :min="formatMax"
        :max="formatMin"
        @input="updateValue"
        v-if="dialog"
      >
        <v-btn text color="red" @click="dialog = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="zuga-blue" @click="$refs.dialog.save(time)">
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'ZTimePicker',
    props: {
      value: {
        required: true,
      },
      label: {
        type: String,
        required: false,
      },
      placeholder: {
        type: String,
        required: false,
      },
      bgColor: {
        type: String,
        default: 'zuga-input-bg',
      },
      activeBgColor: {
        type: String,
        default: `var(--v-zuga-input-active-base)`,
      },
      className: {
        type: String,
        default: '',
      },
      border: {
        type: Boolean,
        default: false,
      },
      icon: {
        default: null,
        required: false,
      },
      fontWeight: {
        default: '300',
      },
      inputType: {
        default: 'text',
      },
      loading: {
        default: false,
      },
      errorMessage: {
        required: false,
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      clearable: {
        required: false,
        default: false,
      },
      min: {
        required: false,
      },
      max: {
        required: false,
      },
    },
    data() {
      return {
        inputName: '',
        dialog: false,
        time: null,
      };
    },
    computed: {
      mergedClass() {
        const classes = ['zuga-input', 'elevation-0'];

        if (this.border) classes.push('zuga-input__border');

        return classes;
      },
      style() {
        return {
          '--v-weight': this.fontWeight,
          '--v-zinput-active-bg-color': this.activeBgColor,
        };
      },
      formatMin() {
        if (!this.min) return null;
        const momentObj = this.$moment(this.min, 'HH:mm', true);
        return momentObj.isValid() ? momentObj.format('HH:mm') : null;
      },
      formatMax() {
        if (!this.max) return null;
        const momentObj = this.$moment(this.max, 'HH:mm', true);
        return momentObj.isValid() ? momentObj.format('HH:mm') : null;
      },
    },
    mounted() {
      this.inputName = Math.random().toString(36).substr(2, 9);
      this.init();
    },
    methods: {
      init() {
        const time = this.$moment(this.value);
        this.time = time.isValid() ? time.format('HH:mm') : null;
        this.$emit('input', time.isValid() ? time.toDate() : null);
      },
      formatTime(time) {
        if (!time) return '';
        const momentObj = this.$moment(time, 'HH:mm', true);
        return momentObj.isValid() ? momentObj.format('HH:mm') : null;
      },
      updateValue(event) {
        const time = this.$moment(event, 'HH:mm', true);
        this.time = time.isValid() ? time.format('HH:mm') : null;
        this.$emit('input', time.isValid() ? time.toDate() : null);
      },
    },
  };
</script>

<style lang="scss">
  .zuga-input {
    font-size: 0.88rem !important;
    font-weight: var(--v-weight);

    .v-input__slot:focus-within {
      background-color: var(--v-zinput-active-bg-color) !important;
    }

    &__border {
      border: solid 1px var(--v-zuga-input-border-base) !important;
    }

    input {
      &::placeholder {
        color: var(--v-zuga-input-ph) !important;
      }
    }
  }
</style>
