import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({
  minimum: 0.1,
  easing: 'ease',
  speed: 800,
  trickleSpeed: 200,
  showSpinner: false,
  barBackground: '#FF0303',
});

export default {
  install(Vue) {
    Vue.prototype.$nprogress = NProgress;

    Vue.mixin({
      beforeRouteEnter(to, from, next) {
        NProgress.start();
        next();
      },
      beforeRouteUpdate(to, from, next) {
        NProgress.start();
        next();
      },
      beforeRouteLeave(to, from, next) {
        NProgress.start();
        next();
      },
      mounted() {
        NProgress.done();
      },
    });
  },
};
