<template>
  <div class="z-input" :style="style">
    <div class="z-input__label" v-if="label">
      {{ label }}
      <div class="required" v-if="required">*</div>
    </div>
    <v-textarea
      hide-details="auto"
      dense
      solo
      flat
      color="zuga-blue"
      :type="inputType"
      :name="inputName"
      :placeholder="placeholder"
      :background-color="bgColor"
      :class="mergedClass"
      :value="value"
      :loading="loading"
      :error-messages="errorMessage"
      @input="updateValue"
    >
      <template v-slot:prepend-inner v-if="icon">
        <v-icon color="#adafbf" size="24">{{ icon }}</v-icon>
      </template>
    </v-textarea>
  </div>
</template>

<script>
  export default {
    name: 'ZTextArea',
    props: {
      value: {
        required: true,
      },
      label: {
        type: String,
        required: false,
      },
      placeholder: {
        type: String,
        required: false,
      },
      bgColor: {
        type: String,
        default: 'zuga-input-bg',
      },
      activeBgColor: {
        type: String,
        default: `var(--v-zuga-input-active-base)`,
      },
      className: {
        type: String,
        default: '',
      },
      border: {
        type: Boolean,
        default: false,
      },
      icon: {
        default: null,
        required: false,
      },
      fontWeight: {
        default: '300',
      },
      inputType: {
        default: 'text',
      },
      loading: {
        default: false,
      },
      errorMessage: {
        required: false,
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        inputName: '',
      };
    },
    computed: {
      mergedClass() {
        const classes = ['zuga-input', 'elevation-0'];

        if (this.border) classes.push('zuga-input__border');

        return classes;
      },
      style() {
        return {
          '--v-weight': this.fontWeight,
          '--v-zinput-active-bg-color': this.activeBgColor,
        };
      },
    },
    mounted() {
      this.inputName = Math.random().toString(36).substr(2, 9);
    },
    methods: {
      updateValue(event) {
        this.$emit('input', event);
      },
    },
  };
</script>

<style lang="scss">
  .zuga-input {
    font-size: 0.88rem !important;
    font-weight: var(--v-weight);

    .v-input__slot:focus-within {
      background-color: var(--v-zinput-active-bg-color) !important;
    }

    &__border {
      border: solid 1px var(--v-zuga-input-border-base) !important;
    }

    input {
      &::placeholder {
        color: var(--v-zuga-input-ph) !important;
      }
    }
  }
</style>
