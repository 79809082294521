import '@mdi/font/css/materialdesignicons.css';
import minifyTheme from 'minify-css-string';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import en from 'vuetify/lib/locale/en';
import tr from 'vuetify/lib/locale/tr';

Vue.use(Vuetify);

const light = {
  'zuga-base': '#FFFFFF',
  'zuga-sidebar': '#1e1e2d',
  'zuga-blue': '#009ef7',
  'zuga-blue-hover': '#f1faff',
  'zuga-red': '#f10054',
  'zuga-content-bg': '#f4f7f9',
  'zuga-input-bg': '#f9f9f9',
  'zuga-input-ph': '#92929f',
  'zuga-input-active': '#f4f4f4',
  'zuga-input-border': '#adafbf',
  'zuga-card-title': '#181c32',
  'zuga-card-subtitle': '#a1a5b7',
  'zuga-grid-checkbox': '#f4f4f4',
  'zuga-purple-grey': '#f9f9f9',
  'zuga-grid-text': '#7e8299',
  'zuga-grid-border': '#F4F4F4',
  'zuga-action-btn-bg': '#F9F9F9',
  'zuga-action-btn-text': '#7E8299',
  'zuga-overlay': '#151521',
};

const dark = {
  'zuga-base': '#1e1e2d',
  'zuga-sidebar': '#1e1e2d',
  'zuga-blue': '#009ef7',
  'zuga-blue-hover': '#212e48',
  'zuga-red': '#f10054',
  'zuga-content-bg': '#151521',
  'zuga-input-bg': '#1b1b29',
  'zuga-input-ph': '#5e6278',
  'zuga-input-active': '#2B2B40',
  'zuga-input-border': '#323248',
  'zuga-card-title': '#FFFFFF',
  'zuga-card-subtitle': '#6d6d80',
  'zuga-grid-checkbox': '#37374e',
  'zuga-purple-grey': '#2b2c40',
  'zuga-grid-text': '#9F9FBC',
  'zuga-grid-border': '#2b2b40',
  'zuga-action-btn-bg': '#2B2B40',
  'zuga-action-btn-text': '#7E8299',
  'zuga-overlay': '#151521',
};

export default new Vuetify({
  theme: {
    options: {
      minifyTheme,
      customProperties: true,
    },
    defaultTheme: 'light',
    themes: {
      light,
      dark,
    },
  },
  lang: {
    locales: { tr, en },
    current: 'en',
  },
  icons: {
    iconfont: 'mdi',
  },
});
