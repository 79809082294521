import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const dynamicMenu = [
  {
    name: 'dashboard',
    path: 'dashboard',
    component: () => import('@/views/pages/dashboard/Index.vue'),
    meta: {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      showOnMenu: true,
    },
  },
  {
    name: 'users',
    path: 'users',
    component: () => import('@/views/pages/users/Index.vue'),
    meta: {
      title: 'Users',
      icon: 'mdi-account',
      showOnMenu: true,
    },
  },
  {
    name: 'download-settings',
    path: 'download-settings',
    component: () => import('@/views/pages/download-settings/Index.vue'),
    meta: {
      title: 'Download Settings',
      icon: 'mdi-arrow-decision',
      showOnMenu: true,
    },
  },
  {
    name: 'resources',
    path: 'resources',
    component: () => import('@/views/pages/resources/Index.vue'),
    meta: {
      title: 'Resources',
      icon: 'mdi-semantic-web',
      showOnMenu: true,
    },
  },
  {
    name: 'link-settings',
    path: 'link-settings',
    component: () => import('@/views/pages/link-settings/Index.vue'),
    meta: {
      title: 'Link Settings',
      icon: 'mdi-link-variant-plus',
      showOnMenu: true,
    },
  },
  {
    name: 'parser-settings',
    path: 'parser-settings',
    component: () => import('@/views/pages/parser-settings/Index.vue'),
    meta: {
      title: 'Parser Settings',
      icon: 'mdi-database-search',
      showOnMenu: true,
    },
  },
  {
    name: 'links',
    path: 'links',
    component: () => import('@/views/pages/links/Index.vue'),
    meta: {
      title: 'Links',
      icon: 'mdi-link',
      showOnMenu: true,
    },
  },
  {
    name: 'agents',
    path: 'agents',
    component: () => import('@/views/pages/agents/Index.vue'),
    meta: {
      title: 'Agents',
      icon: 'mdi-face-agent',
      showOnMenu: true,
    },
  },
  {
    name: 'agent-logs',
    path: 'agent-logs',
    component: () => import('@/views/pages/agent-logs/Index.vue'),
    meta: {
      title: 'Agent Logs',
      icon: 'mdi-face-agent',
      showOnMenu: true,
    },
  },
];

const flatten = (arr) =>
  arr.reduce(
    (acc, cur) => acc.concat(cur.children ? flatten(cur.children) : cur),
    []
  );

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/views/base/default/Index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: flatten(dynamicMenu),
  },
  {
    path: '/auth',
    redirect: '/auth/sign-in',
    component: () => import('@/views/base/auth/Index.vue'),
    children: [
      {
        name: 'sign-in',
        path: 'sign-in',
        component: () => import('@/views/pages/auth/sign-in/Index.vue'),
        meta: {
          title: 'Sign-in',
        },
      },
    ],
  },
];

export const menuRoutes = () =>
  dynamicMenu.filter((route) => route.meta.showOnMenu);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'sign-in') Vue.prototype.$authController.verifyToken();

  const isAuthenticated = store.getters['auth/isAuthenticated'];

  store.commit('grid/reset');

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: 'sign-in' });
  } else {
    next();
  }
});

export default router;
