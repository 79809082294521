<template>
  <div class="z-chart">
    <apexchart
      v-if="value"
      :type="chartType"
      height="350"
      :options="chartOptions"
      :series="series"
      ref="chart"
    ></apexchart>
  </div>
</template>

<script>
  import { CommonConst } from '@/common/store.constants';
  import VueApexCharts from 'vue-apexcharts';
  var en = require('apexcharts/dist/locales/en.json');
  export default {
    name: 'ZChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      value: {
        type: Object,
      },
      report: {
        type: Object,
      },
    },
    data() {
      return {
        en,
        CommonConst,
      };
    },
    computed: {
      currentTheme() {
        return this.$store.getters[CommonConst.THEME] ? 'dark' : 'light';
      },
      categories() {
        return this.value?.categories;
      },
      series() {
        return this.value?.series;
      },
      reportTitle() {
        return this.report?.name;
      },
      reportYAxisTitle() {
        return this.report?.yaxisTitle;
      },
      chartType() {
        return this.report?.chartType;
      },
      chartOptions() {
        if (this.chartType === 'line') {
          return this.lineChartOptions;
        } else {
          return this.stackedBarChartOptions;
        }
      },
      stackedBarChartOptions() {
        return {
          chart: {
            locales: [en],
            defaultLocale: 'en',
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff'],
          },
          dataLabels: {
            show: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            categories: this.categories,
            labels: {
              formatter: (value) => {
                return this.$moment(value).format('DD-MMM');
              },
            },
          },
          fill: {
            opacity: 1,
          },
          colors: [
            '#a0522d',
            '#8b658b',
            '#000000',
            '#0000ff',
            '#00ffff',
            '#008b00',
            '#8b814c',
            '#8b8b7a',
            '#ff3030',
            '#8b2323',
            '#ff0000',
            '#ff34b3',
            '#ff00ff',
            '#800080',
            '#008080',
          ],
          yaxis: {
            showAlways: true,
            forceNiceScale: true,
            min: 0,
            labels: {
              show: true,
            },
          },
          legend: {
            position: 'right',
            horizontalAlign: 'left',
          },
        };
      },
      lineChartOptions() {
        return {
          chart: {
            locales: [en],
            defaultLocale: 'en',
            height: 350,
            type: this.chartType,
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          colors: [
            '#a0522d',
            '#8b658b',
            '#000000',
            '#0000ff',
            '#00ffff',
            '#008b00',
            '#8b814c',
            '#8b8b7a',
            '#ff3030',
            '#8b2323',
            '#ff0000',
            '#ff34b3',
            '#ff00ff',
            '#800080',
            '#008080',
          ],
          dataLabels: {
            enabled: true,
            offsetY: -7,
          },
          stroke: {
            show: true,
            curve: 'smooth',
            width: 1,
          },
          title: {
            text: this.reportTitle,
            align: 'left',
          },
          markers: {
            size: 5,
            shape: 'circle',
            hover: {
              size: 5,
            },
          },
          xaxis: {
            categories: this.categories,
            labels: {
              formatter: (value) => {
                return this.$moment(value).format('DD-MMM');
              },
            },
          },
          yaxis: {
            title: {
              text: this.reportYAxisTitle,
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        };
      },
    },
    watch: {
      currentTheme: {
        handler() {
          this.$refs.chart?.updateOptions({
            theme: {
              mode: this.currentTheme,
            },
          });
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss"></style>
