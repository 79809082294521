import axios from 'axios';

class HttpService {
  constructor(baseURL, timeout = 30000) {
    if (!baseURL) {
      throw new Error('Base URL is required');
    }

    this.instance = axios.create({
      baseURL,
      timeout,
    });

    this.interceptors = {
      request: null,
      response: null,
    };

    this.instance.interceptors.request.use(
      (config) => {
        if (this.interceptors.request) {
          config = this.interceptors.request(config);
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    this.instance.interceptors.response.use(
      (response) => {
        if (this.interceptors.response) {
          response = this.interceptors.response(response);
        }

        return response;
      },
      (error) => Promise.reject(error)
    );
  }

  setRequestInterceptor(interceptor) {
    if (typeof interceptor !== 'function') {
      throw new Error('Interceptor must be a function');
    }
    this.interceptors.request = interceptor;
  }

  setResponseInterceptor(interceptor) {
    if (typeof interceptor !== 'function') {
      throw new Error('Interceptor must be a function');
    }
    this.interceptors.response = interceptor;
  }

  setHeaders(headers) {
    this.instance.defaults.headers.common = {
      ...this.instance.defaults.headers.common,
      ...headers,
    };
  }

  setErrorHandler(handler) {
    if (typeof interceptor !== 'function') {
      this.instance.interceptors.response.use(
        (response) => response,
        (error) => {
          return handler(error);
        }
      );
    }
  }

  get(url, params = {}, config = {}) {
    return this.instance.get(url, { params, ...config });
  }

  post(url, data = {}, config = {}) {
    return this.instance.post(url, data, config);
  }

  put(url, data = {}, config = {}) {
    return this.instance.put(url, data, config);
  }

  delete(url, config = {}) {
    return this.instance.delete(url, config);
  }
}

export default HttpService;
