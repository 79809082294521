import BaseController from '@/common/base.controller';
import { CommonConst } from '@/common/store.constants';
import store from '@/store';

class LinkSettingController extends BaseController {
  constructor(httpService) {
    super(httpService);
  }

  async switchStatus(path, payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING_WITH_KEY, payload.id, {
        root: true,
      });

      this.insert(path, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING_WITH_KEY, null, {
            root: true,
          });
        });
    });
  }
}

export default LinkSettingController;
