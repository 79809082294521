import BaseController from '@/common/base.controller';
import { CommonConst, GridConst } from '@/common/store.constants';
import store from '@/store';

class GridController extends BaseController {
  constructor(httpService) {
    super(httpService);
  }

  async getGridData(path, payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      super
        .getGridData(path, payload)
        .then((response) => {
          store.commit(GridConst.SET_DATA, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }

  async getPreset(path, payload = null) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      super
        .getPreset(path, payload ?? {})
        .then((response) => {
          store.commit(GridConst.SET_PRESET, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }

  async get(path, payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      super
        .get(path, payload)
        .then((response) => {
          store.commit(GridConst.UPSERT_ITEM, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }

  async save(path, payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      this.insert(path, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }

  async delete(path, payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      super
        .delete(path, { id: payload.id })
        .then((response) => {
          store.commit(GridConst.DELETE_ITEM, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }
}

export default GridController;
