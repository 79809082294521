const state = {
  data: null,
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
};

const actions = {};

const getters = {
  chartData: (state) => state.data?.chartData,
  tableData: (state) => state.data?.tableData,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
