import { API_BASE_URL } from '@/common/env.constants';
import HttpService from '@/common/http.service';
import store from '@/store';
import camelcaseKeys from 'camelcase-keys';
import Vue from 'vue';

import VueAxios from 'vue-axios';

class PaasService extends HttpService {
  constructor() {
    super(API_BASE_URL);
    this.setRequestInterceptor(this.requestInterceptor);
    this.setResponseInterceptor(this.responseInterceptor);
    this.setErrorHandler(this.errorHandler);
    Vue.use(VueAxios, this);
  }

  requestInterceptor = (config) => {
    Vue.prototype.$nprogress.start();
    const modifiedConfig = config;

    const token = store.getters['auth/token'];
    if (token) {
      modifiedConfig.headers.Authorization = `${token}`;
    }

    modifiedConfig.data = {
      data: camelcaseKeys(config.data, { deep: true }),
    };
    return modifiedConfig;
  };
  responseInterceptor = (response) => {
    Vue.prototype.$nprogress.done();
    const modifiedResponse = response;
    modifiedResponse.data = camelcaseKeys(modifiedResponse.data?.Data, {
      deep: true,
    });
    return response.data;
  };

  errorHandler = (error) => {
    Vue.prototype.$nprogress.done();

    let errorMessage = 'An unknown error occurred';

    if (error.code === 'ECONNABORTED') {
      errorMessage = 'Request timeout. Please try again later.';
    } else {
      const responseData = error?.response?.data;
      if (responseData) {
        const camelCasedData = camelcaseKeys(responseData, { deep: true });

        const { httpStatus, responseCode, responseText } = camelCasedData || {};

        if (httpStatus === 401 && responseCode === 6003) {
          Vue.prototype.$authController.logout();
        }

        errorMessage = responseText || errorMessage;
      } else {
        errorMessage = 'Undefined response data';
      }
    }

    return Promise.reject(errorMessage);
  };
}

export default PaasService;
