export async function loadFonts() {
  const webFontLoader = await import('webfontloader');

  return new Promise((resolve) => {
    webFontLoader.load({
      google: {
        families: [
          'Roboto:100,300,400,500,700,900',
          'Inter:100,300,400,500,700,900',
        ],
      },
      active: resolve,
    });
  });
}
