<template>
  <v-card color="transparent" flat width="100%">
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
      <v-spacer></v-spacer>
      <z-icon-button icon="mdi-plus" @action="add"></z-icon-button>
    </v-card-title>
    <v-card-text class="pa-0">
      <div v-if="length === 0" class="text-center">no record</div>
      <v-list dense color="transparent" v-else>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-content>
            <slot name="items" :item="item" :index="index"></slot>
          </v-list-item-content>
          <v-list-item-action>
            <z-icon-button
              icon="mdi-minus"
              @action="remove(index)"
            ></z-icon-button>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'ZPagedList',
    props: {
      items: {
        default: () => [],
        type: Array,
      },
      title: {
        type: String,
      },
      defaultFormData: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        dataItems: [],
      };
    },
    mounted() {
      this.dataItems = this.items;
    },
    computed: {
      length() {
        return this.items.length;
      },
    },
    methods: {
      add() {
        const defaultItem = this.$clonedeep(this.defaultFormData);
        this.dataItems.push(defaultItem);
      },
      remove(index) {
        this.dataItems.splice(index, 1);
      },
    },
    watch: {
      dataItems: {
        handler() {
          this.$emit('update:items', this.dataItems);
        },
      },
    },
  };
</script>

<style></style>
