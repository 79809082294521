<template>
  <v-dialog
    content-class="z-dialog"
    overlay-color="zuga-overlay"
    v-model="dialog"
    persistent
    max-width="800px"
    :style="style"
  >
    <v-card color="zuga-base" elevation="0">
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text class="px-2">
        <component
          ref="form"
          v-bind:is="formComponent"
          @valid="valid"
        ></component>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="zuga-btn zuga-card-subtitle--text"
          depressed
          dark
          color="zuga-content-bg"
          @click="close"
        >
          Close
        </v-btn>

        <v-btn class="zuga-btn" depressed dark color="zuga-blue" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { CommonConst } from '@/common/store.constants';
  export default {
    name: 'FormDialog',
    props: {
      formDialog: {
        type: Boolean,
        default: false,
      },
      formComponent: {
        required: false,
      },
      title: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        dialog: false,
        invalid: false,
      };
    },
    computed: {
      style() {
        return {
          '--v-dialog-shadow': this.$store.getters[CommonConst.THEME]
            ? '0 0.5rem 1rem rgba(0, 0, 0, 0.1)'
            : '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
        };
      },
      isInvalid() {
        return this.invalid;
      },
      formData() {
        return this.$refs.form.$v.formData;
      },
    },
    mounted() {
      this.dialog = this.formDialog;
    },
    methods: {
      valid(isValid) {
        this.invalid = isValid;
      },
      close() {
        this.$emit('update:formDialog', false);
        this.formData.$reset();
      },
      save() {
        if (this.isInvalid) {
          this.formData.$touch();
          return;
        }
        this.$emit('save', {
          successFn: () => {
            this.formData.$reset();
          },
          errorFn: () => {},
        });
      },
    },
    watch: {
      formDialog: {
        handler() {
          this.dialog = this.formDialog;
        },
      },
    },
  };
</script>

<style scoped></style>
