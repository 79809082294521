const state = {
  darkTheme: false,
  loading: false,
  loadingWithKey: null,
  drawer: false,
};

const mutations = {
  toggleTheme(state) {
    state.darkTheme = !state.darkTheme;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  setLoadingWithKey(state, loadingKey) {
    state.loadingWithKey = loadingKey;
  },
  toggleDrawer(state, status = null) {
    let newStatus = status;
    if (status == null) {
      newStatus = !state.drawer;
    }

    Object.assign(state, { drawer: newStatus });
  },
};

const actions = {};

const getters = {
  theme: (state) => state.darkTheme,
  isLoading: (state) => state.loading,
  isLoadingWithKey: (state) => state.loadingWithKey,
  drawer: (state) => state.drawer,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
