const state = {
  token: null,
  user: null,
  permissions: null,
  config: {
    endpoints: {},
  },
};

const mutations = {
  setUser(state, { token, ...userWithoutToken }) {
    state.token = token;
    state.user = userWithoutToken;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  clearUser(state) {
    state.token = null;
    state.permissions = null;
    state.user = null;
  },
};

const actions = {};

const getters = {
  endpoints: (state) => state.config.endpoints,
  token: (state) => state.token,
  isAuthenticated: (state) => state.user !== null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
