import GlobalComponents from '@/plugins/global.components';
import GlobalController from '@/plugins/global.controller';
import GlobalFilters from '@/plugins/global.filters';
import GlobalFunctions from '@/plugins/global.functions';
import GlobalServices from '@/plugins/global.service';
import '@fortawesome/fontawesome-free/css/all.css';
import moment from 'moment';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';

import VueMoment from 'vue-moment';
import NProgress from './plugins/progress';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

loadFonts();

Vue.use(VueMoment, { moment });
Vue.use(Vuelidate);
Vue.use(NProgress);

Vue.use(GlobalServices);
Vue.use(GlobalController);
Vue.use(GlobalComponents);
Vue.use(GlobalFunctions);
Vue.use(GlobalFilters);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
