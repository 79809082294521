import BaseController from '@/common/base.controller';
import { CommonConst, GridConst } from '@/common/store.constants';
import store from '@/store';

class LinkController extends BaseController {
  constructor(httpService) {
    super(httpService);
  }

  async addQueue(path, payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      this.insert(path, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }

  async getFilterPreset(path, payload = null) {
    return new Promise((resolve, reject) => {
      super
        .getPreset(path, payload ?? {})
        .then((response) => {
          store.commit(GridConst.SET_FILTER_PRESET, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {});
    });
  }
}

export default LinkController;
